.idn-app-content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0px;
    margin-left: -2%;
    margin-bottom: -2%;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    &--width {
        width: 73%;
    }
}






@media all and (max-width:800px) {
    .idn-app-content {
        &--width {
            width: 100%;
        }
    }
}

@media all and (max-width:768px) {
    .idn-app-content {
        margin-left: 0px;
    }
}