.message-box {
    $border-size: 2px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    position: relative;
    padding: 6px 10px;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(62, 175, 63, 0.15);
    background: #FFFFFF;
    background-clip: padding-box;
    border: solid $border-size transparent;
    border-radius: 1em;
    font-family: 'Elior';
    font-style: normal;
    color: #000000;


    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border-size;
        /* !importanté */
        border-radius: inherit;
        /* !importanté */
        background: var(--gradient-1);
    }

    &__title {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
    }

    &__content,
    &__time {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
    }

    &__content {
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -moz-text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .block:hover {
        background-color: $color-hover;
    }
}