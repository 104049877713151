.idn-message {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 5px;
    background-color: #333;

}



.idn-message [class*="idn-icon"] {
    width: 24px;
    -ms-flex: 0 0 24px;
    flex: 0 0 24px;
    margin-right: 10px;
    color: #fff;
    font-size: 24px
}

.idn-message-content {
    color: #fff
}

.idn-message-content a {
    color: #fff;
    text-decoration: underline
}

.idn-message.idn-info {
    background-color: #0085ca
}

.idn-message.idn-warning {
    background-color: #ff9e1e
}

.idn-message.idn-error {
    background-color: #FF7675
}

.idn-audience-bule {
    border: 1px solid var(--color-1) !important;
}

.idn-list-audience {
    list-style-type: disc;
}

.idn-btn-list-audience {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    font-weight: bold;
    font-family: inherit;
    cursor: pointer;
    box-sizing: border-box;
    background: var(--gradient-1);
    border-radius: 50px;
    border: none;
    transition: all 0.3s;
    outline: none;
}

.idn-btn-list-file {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    font-size: inherit;
    font-family: inherit;
    box-sizing: border-box;
    background: var(--gradient-1);
    border-radius: 50px;
    border: none;
    transition: all 0.3s;
    outline: none;
    margin-left: 5px;
}


.idn-message-card {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--color-1);
    gap: 15px;
    padding-bottom: 4px;

    &:not(:first-child) {
        padding-top: 20px;
    }

    &__title {
        font-weight: bold;
        font-size: 17px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -moz-text-overflow: ellipsis;
    }

    &__desc {
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -moz-text-overflow: ellipsis;
    }

    .idn-button {
        width: 120px;
    }

    &:hover {
        background-color: $color-hover;

    }

    &--unread {
        border-right: 4px solid var(--color-2);
    }

    &--read {
        color: grey;
    }

    &--read-button {
        filter: grayscale(0.8);
    }

    &--unread-color {
        color: var(--color-2);
    }

    &--select {
        border: 1px solid var(--color-1);
        padding: 4px;
        box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.4);
        background-color: rgb(187, 186, 186);
    }
}