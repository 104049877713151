.p-font {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: var(--color-1);
    color: var(--color-1);
    background-color: rgb(240, 240, 240);
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: var(--color-1);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    color: var(--color-1);
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
    margin-bottom: 2px;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--color-1);
    background: var(--color-1);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: var(--color-1);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--color-1);
}

.p-treeselect-label {
    white-space: pre-wrap !important;
}


.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trunc-data {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
}

.p-inputtext:enabled:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--color-1);
}

.p-inputtext {
    padding: 0.5rem 0.75rem;
}

.com-list {
    .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 1.5rem;
        height: 1.5rem;
        margin: 0;
    }

    .p-paginator .p-paginator-pages {
        display: flex;
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        width: 0;
        min-width: 1.5rem;
        height: 1.5rem;
        margin: 0;
    }


}

.p-scrollpanel.custombar2 .p-scrollpanel-bar:not(.p-scrollpanel-bar-x) {
    background-color: var(--color-1);
    opacity: 1;
    transition: background-color .2s;
}

.p-scrollpanel.custombar2 .p-scrollpanel-bar:not(.p-scrollpanel-bar-y) {
    background-color: transparent;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #EEF2FF;
    border-color: #EEF2FF;
    color: var(--color-1);
}

/* DataViewDemo.css */

.dataview-demo .p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.dataview-demo .product-name {
    font-size: 1.5rem;
    font-weight: 700;
}

.dataview-demo .product-description {
    margin: 0 0 1rem 0;
}

.dataview-demo .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.dataview-demo .product-category {
    font-weight: 600;
    vertical-align: middle;
}

.dataview-demo .product-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.dataview-demo .product-list-item img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
}

.dataview-demo .product-list-item .product-list-detail {
    flex: 1 1 0;
}

.dataview-demo .product-list-item .p-rating {
    margin: 0 0 .5rem 0;
}

.dataview-demo .product-list-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
    align-self: flex-end;
}

.dataview-demo .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
}

.dataview-demo .product-list-item .p-button {
    margin-bottom: .5rem;
}

.dataview-demo .product-grid-item {
    margin: .5em;
    border: 1px solid #dee2e6;
    padding: 2rem;
}

.dataview-demo .product-grid-item .product-grid-item-top,
.dataview-demo .product-grid-item .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dataview-demo .product-grid-item img {
    width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
}

.dataview-demo .product-grid-item .product-grid-item-content {
    text-align: center;
}

.dataview-demo .product-grid-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
}

.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-unselectable-text {
    display: flex;
    align-items: center;
    justify-content: center;

    &--width {
        width: 100%;
    }
}

.idn-button-bg-color .p-button,
.idn-p-button,
.idn-btn {
    background: var(--gradient-1);
    border: none;
}


.p-button.idn-p-button {
    &--heigth-for-filter {
        height: 10px;
    }
}

.idn-card-list {

    .btn {
        padding: 0 10px;
    }

    .btn:focus {
        box-shadow: none;
    }

    .btn-primary:focus {
        box-shadow: none;
    }

    .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none;
    }



    .p-panelmenu .p-panelmenu-header>a {
        padding: 0.5rem;
        border: none;
        background: none;
        color: black;
    }

    .p-panelmenu-panel {
        background-color: transparent;
    }

    .p-panelmenu .p-panelmenu-header>a:focus {
        box-shadow: none;
    }

    .dropdown-menu {
        background-color: #f6f6f6;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
        border: 1px solid #cccccc;
    }

    .p-panelmenu .p-panelmenu-content {
        padding-left: 35px;
        border: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .p-radiobutton {

        width: 18px;
        height: 18px;

        .p-radiobutton-box {
            width: 18px;
            height: 18px;
        }

        .p-radiobutton-box .p-radiobutton-icon {
            width: 7px;
            height: 7px;
        }

        .p-radiobutton-box.p-highlight {
            border-color: var(--color-1);
            background: var(--color-1);
        }

        &:not(.p-radiobutton-box-disabled) .p-radiobutton-box:hover,
        &:not(.p-radiobutton-box-disabled) .p-radiobutton-box.p-focus:hover {
            border-color: var(--color-1);
        }

        &:not(.p-radiobutton-box-disabled) .p-radiobutton-box.p-radiobutton-box.p-highlight:hover {
            background: var(--color-1);
        }
    }

    .p-checkbox {

        width: 18px;
        height: 18px;

        &:not(.p-checkbox-box-disabled) .p-checkbox-box.p-focus {
            border: 2px solid #ced4da;
            box-shadow: none;
        }

        &:not(.p-checkbox-box-disabled) .p-checkbox-box:hover,
        &:not(.p-checkbox-box-disabled) .p-checkbox-box.p-focus:hover {
            border-color: var(--color-1);
        }

        &:not(.p-checkbox-box-disabled) .p-checkbox-box.p-checkbox-box.p-highlight:hover {
            background: var(--color-1);
        }

        .p-checkbox-box {
            width: 18px;
            height: 18px;
        }

        .p-checkbox-box .p-checkbox-icon {
            font-size: 10px;
        }

        .p-checkbox-box.p-highlight {
            border-color: var(--color-1);
            background: var(--color-1);
        }
    }
}


.idn-btn {
    &--heigth-for-filter {
        height: 25px;
        line-height: 25px;
        font-weight: bold;
    }

}



.p-fluid .p-calendar {
    &--width {
        width: 100%;
    }
}

.p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: var(--color-1);
}


.p-button {
    &--model {
        border-radius: 100px;
        height: 44px;
        padding: 0 24px;
    }

    &--model:hover {
        text-decoration: none !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    }

    &.p-button-outlined {
        &--model-border-color {
            border-color: var(--color-1);
        }

        &--model-color {
            color: var(--color-1);
        }
    }

    &--model-border-color {
        border-color: var(--color-1);
    }

    &--model-color {
        color: var(--color-1);
    }

    &--confirm {
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        margin: 10px;
    }
}


.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
    background: var(--color-1);
    color: #ffffff;
    border-color: var(--color-1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15)
}

.p-orderlist-controls {
    .p-button {
        background: var(--color-1);
        border: 1px solid var(--color-1)
    }
}

.p-button-text {
    &--black {
        color: black !important;
    }
}

.p-button.idn-p-button:hover {
    background: var(--gradient-1);
    border: none;
    color: #fff !important;
    text-decoration: none !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15)
}


.idn-page-content .p-panel.p-panel-toggleable .p-panel-header {
    padding: 4px;
}


.p-button-text {
    background: white;
}

.idn-display-message {
    .ql-editor {
        padding: 0;
        padding-right: 5px;
    }

    &.p-editor-container .p-editor-content.ql-snow {
        border: 0;
    }

    h1 {
        font-size: 1.8em;
    }
}

.column-table-15 {
    width: 15%;
}

.column-table-10 {
    width: 10%;
}

.column-table-5 {
    width: 5%;
}

.p-overlay-badge .p-badge{
    top:4px !important;
    right: -15px !important;
}

.p-badge{
    background:none !important;
    font-size: 1rem !important;
    color: #495057 !important;
}



@media screen and (max-width: 1500px) {

    .column-table-15 {
        width: 5% !important;
    }

    .column-table-10 {
        width: 1% !important;
    }

    .column-table-5 {
        width: 1% !important;
    }

}

@media screen and (max-width: 1360px) {

    .column-table-15 {
        width: 1% !important;
    }

    .column-table-10,
    .column-table-5,
    .column-table-15 {
        font-size: 10px;
    }


}

@media screen and (max-width: 1123px) {

    .column-table-10,
    .column-table-5,
    .column-table-15 {
        font-size: 8px;
    }


}


@media screen and (max-width: 960px) {
    .datatable-crud-demo .p-toolbar {
        flex-wrap: wrap;
    }

    .datatable-crud-demo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }

    .datatable-crud-demo .table-header {
        flex-direction: column;
    }

    .datatable-crud-demo .table-header .p-input-icon-left,
    .datatable-crud-demo .table-header input {
        width: 100%;
    }
}