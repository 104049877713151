.idn-user-notif {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
    border-radius: 4px;

    &__item {
        font-family: 'Elior';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        color: #333333;
        padding: 16px;
        width: 100%;
        border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
    }

    &__item div:first-child {
        margin-bottom: 5px;
    }

    &__item:not(.idn-user-notif--not-hover):hover {
        background-color: $color-hover;
        cursor: pointer;
    }

    &__item:not(.idn-user-notif--not-hover):hover a {
        color: var(--color-1);
    }

    &--bg-color {
        background-color: $color-hover;
    }

    &--font {
        width: 100%;
        font-family: 'Elior';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        text-align: center;

        a,
        .info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-left: 20px;
            padding-right: 20px;
        }


        a,
        a:visited {
            color: #333333;
        }

        a:hover {
            text-decoration-line: none;
            color: var(--color-1);
        }

    }
}

@media all and (max-width:800px) {
    .idn-user-notif {
        width: 100%;
    }
}