/*!
 * Elior.IdNum v0.1.0 Core css file 
 * Last modification: 2021/12/09
 * Author: Cellenza
 */
:root {
    --color-1: #65b32e;
    --color-2: #c8d300;
    --color-hover: #dedddd;
    --gradient-1: linear-gradient(114.21deg, #65b32e 8.94%, #c8d300 122.39%);
    --logo: url('../../images/idn-eliorgroup-logo.png');
    --page-bkg: url('../../images/idn-eliorgroup-page-bkg.png');
    --page-bkg-2: url('../../images/idn-eliorgroup-page-bkg-2.png');
    --page-bkg-3: url('../../images/idn-eliorgroup-page-bkg-3.png');
    --page-bkg-4: url('../../images/idn-eliorgroup-page-bkg-4.png');
}

$color-success: #78be20;
$color-error: #FF7675;
$color-hover: #f7f7f7;

$generel-width : 75%;
$generel-width-sup-1024: 100%;
$generel-width-sup-1085: 72%;
$generel-width-sup-1140 : 74%;
$generel-width-sup-1255 : 70%;
$generel-width-sup-1361 : 73%;
$generel-width-sup-1500 : 81%;
$generel-width-sup-1600 : 80%;
$generel-width-sup-2000 : 82%;