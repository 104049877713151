.idn-link-content {
    width: 24%;
    background-color: white;

    &-show {
        display: block;
    }

    &-display {
        display: block;
    }
}

@media all and (max-width:1500px) {
    .idn-link-content {
        margin-bottom: 40px;
    }
}

@media all and (max-width:800px) {
    .idn-link-content {
        width: 100%;
        margin-bottom: 0;

        &-hide {
            display: none;
            animation: hideIdnUserNav 300ms both;
        }
    }
}