.idn-error404 {
    background: var(--page-bkg-3) no-repeat left center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.idn-error404-page {
    background: var(--page-bkg-2) no-repeat 80% top;
    padding-top: 16px;
    font-family: 'Elior';
    font-style: normal;
    width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__header {
        font-weight: 700;
        font-size: 200px;
        line-height: 201px;
        text-align: center;
        color: $color-error;
    }

    &__title {
        font-family: 'EliorBold';
        font-weight: 800;
        font-size: 42px;
        line-height: 52px;
        text-align: center;
        color: #333333;
    }

    &__desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #333333;
    }

    &--font-size {
        font-size: 18px;
    }

    &--text-align-left {
        text-align: left;
    }
}

body {
    @if var(--isError404) {
        background-color: #333333;
    }
}

@media all and (max-width:1024px) {
    .idn-error404 {
        background: none;
    }

    .idn-error404-page {
        background: none;
    }
}

@media all and (max-width:650px) {
    .idn-error404-page {
        padding: 20px;
        width: 100%;
    }


}

@media all and (max-width:390px) {
    .idn-error404-page {

        &__header {
            font-size: 150px;
            line-height: 150px;
        }

        &__title {
            font-size: 32px;
            line-height: 32px;
        }
    }
}