@import './utils/variables';
@import './utils/keyframes';

@import './base/normalize';
@import './base/font';

@import 'composants/idn-card';
@import 'composants/idn-user-group';
@import 'composants/idn-user-notif';
@import 'composants/idn-primereact';
@import 'composants/idn-message';
@import 'composants/idn-block-button';
@import 'composants/idn-ql';

@import 'layout/newMessage';
@import 'layout/applicationList';
@import 'layout/quickLinkList';

@import 'pages/headerContent';
@import 'pages/idn-error-page';
@import 'pages/home';
@import 'pages/idn-dashboard-page';

.idn-icon-interrogation:before {
    content: "\e905"
}

.idn-icon-quick-link:before {
    content: "🔗";
    font-size: 20px;
    color: white;
    margin-bottom: 2px;
}

.idn-icon-megaphone:before {
    content: "\e908"
}

.idn-icon-idea:before {
    content: "\e909"
}

.idn-icon-alert:before {
    content: "\e90a"
}

.idn-icon-check:before {
    content: "\e902"
}

.idn-icon-arrowright:before {
    content: "\e900"
}

.idn-icon-arrowtop:before {
    content: "\e901"
}

.idn-icon-edit:before {
    content: "\e904"
}

.idn-icon-search:before {
    content: "\e90e"
}

.idn-icon-arrowbottom:before {
    content: "\e913"
}

.idn-icon-arrowleft:before {
    content: "\e914"
}

h1 {
    font-size: 2.25em;
    color: #333;
    font-weight: bold
}

h2 {
    font-size: 1.4375em;
    color: #333;
    font-weight: bold
}

h3 {
    font-size: 1.25em;
    color: #333;
    font-weight: bold
}

h4 {
    font-size: 1.125em;
    color: #333;
    font-weight: bold
}

h5 {
    font-size: 1em;
    color: #333;
    font-weight: bold
}

h6 {
    font-size: .875em;
    color: #333;
    font-weight: bold
}

p {
    margin: 0 0 10px;
    line-height: 1.3em
}

strong,
b {
    font-weight: 700
}

hr.idn-ruller {
    width: 200px;
    height: 2px;
    margin: 30px auto;
    border: none;
    background-color: #949494
}

ul.idn-list {
    list-style: disc inside none
}

ul.idn-list li {
    padding: 2px 0
}

ul.idn-list li a {
    color: var(--color-1)
}

ul.idn-list-arrow {
    list-style: none
}

ul.idn-list-arrow li:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e900"
}

ul.idn-list-arrow li:before {
    display: inline-block;
    margin-right: 7px;
    font-size: .625em;
    color: var(--color-1)
}

ul.idn-list-arrow li a:link {
    color: #333;
    font-weight: bold
}

ul.idn-list-arrow li a:visited {
    color: #333
}

ul.idn-list-bullet {
    list-style: none
}

ul.idn-list-bullet li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    list-style-type: none;
}

.popover {
    max-width: 100%;
    background-color: rgb(235, 235, 235);
}

.first-letter-style {
    float: left;
    margin-right: 0.5rem;
    font-size: 3.5rem;
    line-height: 1;
    margin-top: 2px;
    font-family: "DM Serif Display", serif;
}

.show-on-mobile {
    display: none;
    width: 100%;
}

.separator {
    display: none;
    align-items: center;
    text-align: center;
    margin: 0;
    text-transform: capitalize;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}

ul.idn-list-bullet li:not(:first-child) {
    margin-top: 7px
}

ul.idn-list-bullet li:not(.idn-else) {
    font-weight: 700
}

ul.idn-list-bullet li:not(.idn-else):before {
    -ms-flex: 0 0 8px;
    flex: 0 0 8px;
    width: 8px;
    height: 8px;
    display: block;
    margin-right: 5px;
    border: 2px solid #333;
    transition: all .3s;
    box-sizing: border-box;
    border-radius: 50%
}

ul.idn-list-bullet li.idn-success {
    color: $color-success;

}

ul.idn-list-bullet li.idn-success:before {
    background: $color-success;
    border: none;
}

.CloseTabLng:hover {
    color: $color-error
}

ul.idn-list-bullet li.idn-error {
    color: $color-error
}

ul.idn-list-bullet li.idn-error:before {
    background: $color-error;
    border: none
}

a {
    text-decoration: none;
    color: var(--color-1)
}


a.color-black {
    color: #000000;
}

a:visited {
    text-decoration: none;
    color: var(--color-1)
}

a:hover,
a.color-black:hover {
    text-decoration: underline;
    color: var(--color-1)
}

.idn-button {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 44px;
    padding: 0 24px;
    color: #fff !important;
    font-weight: bold;
    font-family: inherit;
    cursor: pointer;
    box-sizing: border-box;
    background: var(--gradient-1);
    border-radius: 100px;
    border: none;
    transition: all .3s;
    outline: none;

    &--square {
        flex-direction: column;
        width: 260px;
        height: 152px;
        border-radius: 10px;
        font-size: 20px;
        margin-left: 2%;
        margin-bottom: 2%;
    }

}



.idn-button:active,
.idn-button:focus,
.idn-button:hover {
    color: #fff !important;
    text-decoration: none !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15)
}

.idn-button:active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3)
}

.idn-button.idn-width-full {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: center
}

.idn-button.idn-cancel {
    color: #fff;
    background: #353535
}

.idn-button.idn-cancel:active,
.idn-button.idn-cancel:focus,
.idn-button.idn-cancel:hover {
    color: #fff !important
}

.idn-button.idn-delete {
    color: #fff;
    background: $color-error
}

.idn-button.idn-delete:active,
.idn-button.idn-delete:focus,
.idn-button.idn-delete:hover {
    color: #fff !important
}

.idn-button.idn-disabled {
    color: #fff;
    cursor: default;
    background: #c4c4c4 !important
}

.idn-button.idn-disabled:active,
.idn-button.idn-disabled:focus,
.idn-button.idn-disabled:hover {
    color: #fff !important;
    background: #c4c4c4 !important;
    box-shadow: none !important;
    cursor: not-allowed
}

.idn-button.idn-bordered {
    color: #333 !important;
    background: #fff;
    border: 2px solid var(--color-1)
}

.idn-button[class^="idn-icon-"]:before,
.idn-button[class*=" idn-icon-"]:before {
    margin: 0 10px 0 0;
    font-size: 16px
}

.idn-button.idn-icon-only {
    width: 44px
}

.idn-button.idn-icon-only:before {
    margin: 0
}

.idn-button.idn-size-l {
    height: 60px;
    font-size: 1.25em
}

.idn-buttons-group {
    display: -ms-flexbox;
    display: flex
}

.idn-buttons-group .idn-button {
    border-radius: 0
}

.idn-buttons-group .idn-button:first-of-type {
    border-radius: 100px 0 0 100px
}

.idn-buttons-group .idn-button:last-of-type {
    border-radius: 0 100px 100px 0
}

.idn-buttons-group .idn-button.idn-selected {
    cursor: default;
    background-color: #333
}

.idn-table {
    width: 100%
}

.idn-table th {
    text-transform: uppercase;
    background-color: #333
}

.idn-table td,
.idn-table th {
    padding: 2px 5px
}

.idn-form-item {
    margin-bottom: 20px
}

.fileselected {
    text-decoration: underline;
    margin-bottom: 20px;
}

.idn-form-fieldset {
    margin: 0 0 20px;
    border: none
}

.idn-form-field--disabled {
    cursor: no-drop;
    background: #e9ecef;
}

.idn-form-field--disabled input,
.idn-form-field--disabled select {
    cursor: no-drop;
}

.idn-form-fieldset legend {
    display: block;
    width: 100%;
    margin: 0 0 12px;
    padding-bottom: 7px;
    font-size: 1.25em;
    text-align: left;
    color: var(--color-1);
    font-family: inherit;
    border-bottom: 1px solid var(--color-1);
    font-weight: 700
}

.idn-form-label {
    display: block;
    margin-bottom: 5px;
    font-size: 1em;
    font-weight: bold;
    font-family: inherit
}

.idn-form-required {
    color: $color-error
}

.idn-form-field {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #949494;
    transition: border-color .3s cubic-bezier(.3, .21, .21, 1.25);
    border-radius: 5px;

    .password-icon {
        transition: all 0.2s;
        padding-right: 10px;
    }

    .password-icon:hover {
        cursor: pointer;
        color: var(--color-1);
    }

    &:hover {
        border-color: var(--color-1)
    }

    &.idn-focused {
        border-color: var(--color-1)
    }

    .p-treeselect {
        border: none;
        width: 100%;
    }
}


.idn-form-field input,
.idn-form-field textarea,
.idn-form-field select,
.css-b62m3t-container .css-1s2u09g-control,
.css-b62m3t-container .css-1pahdxg-control {
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    padding: 14px 15px;
    font-size: .875em;
    color: #333;
    outline: none;
    box-sizing: border-box;
    border: none;
    background: none;
    border-width: 0;
    border: none;
    border-width: 0;
    box-shadow: none;
}

.css-b62m3t-container {
    width: 100%;
}

.css-b62m3t-container .css-1s2u09g-control,
.css-b62m3t-container .css-1pahdxg-control {
    padding: 3px 4px;
}

.css-b62m3t-container .css-1pahdxg-control:hover {
    border: none;
    border-color: none;
}

.idn-form-field input {
    &.input-search {
        padding-left: 35px;
    }
}

.css-b62m3t-container,
.css-3iigni-container {
    width: 100%;
}

.css-b62m3t-container .css-1s2u09g-control,
.css-b62m3t-container .css-1pahdxg-control {
    padding: 3px 4px;
}

.css-b62m3t-container .css-1pahdxg-control:hover {
    border: none;
    border-color: none;
}

.idn-form-field input::-webkit-input-placeholder,
.idn-form-field textarea::-webkit-input-placeholder,
.idn-form-field select::-webkit-input-placeholder {
    color: #b3b3b3
}

.idn-form-field input::-moz-placeholder,
.idn-form-field textarea::-moz-placeholder,
.idn-form-field select::-moz-placeholder {
    color: #b3b3b3
}

.idn-form-field input:-ms-input-placeholder,
.idn-form-field textarea:-ms-input-placeholder,
.idn-form-field select:-ms-input-placeholder {
    color: #b3b3b3
}

.idn-form-field input:-moz-placeholder,
.idn-form-field textarea:-moz-placeholder,
.idn-form-field select:-moz-placeholder {
    color: #b3b3b3
}

.idn-form-field textarea {
    color: #333;
    overflow: auto;
    vertical-align: top;
    resize: vertical
}

.idn-form-field input[type=date],
.idn-form-field input[type=datetime],
.idn-form-field input[type=datetime-local],
.idn-form-field input[type=month],
.idn-form-field input[type=time],
.idn-form-field input[type=week] {
    padding: 12px 15px;
    font-size: .875em;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: 1px
}

.idn-form-checkbox input[type="checkbox"] {
    position: absolute;
    left: -2000px
}

.idn-form-checkbox input[type="checkbox"]+label {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #333
}

.idn-form-checkbox input[type="checkbox"]+label:before {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex: 0 0 17px;
    flex: 0 0 17px;
    width: 17px;
    height: 17px;
    margin: 0 10px 0 0;
    content: "";
    color: transparent;
    border: 2px solid #4d4d4d;
    transition: border-color .3s;
    border-radius: 2px;
    box-sizing: border-box
}

.idn-form-checkbox input[type="checkbox"]+label:after {
    position: absolute;
    left: 4px;
    width: 9px;
    height: 9px;
    content: '';
    transition: background .3s
}

.idn-form-checkbox input[type="checkbox"]+label:hover:before {
    border-color: var(--color-1)
}

.idn-form-checkbox input[type="checkbox"]:focus+label:before {
    border-color: var(--color-1)
}

.idn-form-checkbox input[type="checkbox"]:checked+label:before {
    border-color: var(--color-1)
}

.idn-form-checkbox input[type="checkbox"]:checked+label:after {
    background-image: var(--gradient-1)
}

.idn-form-checkbox input[type="checkbox"]:disabled+label {
    color: #c4c4c4
}

.idn-form-checkbox input[type="checkbox"]:disabled+label:before {
    border-color: #c4c4c4 !important
}

.idn-form-checkbox input[type="checkbox"]:disabled:checked+label:before {
    border-color: #c4c4c4
}

.idn-form-checkbox input[type="checkbox"]:disabled:checked+label:after {
    background: #c4c4c4
}

.idn-form-checkboxes {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.idn-form-checkboxes .idn-form-checkbox {
    margin-right: 30px
}

.idn-form-radio input[type="radio"] {
    position: absolute;
    left: -2000px
}

.idn-form-radio input[type="radio"]+label {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #333
}

.idn-form-radio input[type="radio"]+label:before {
    margin: -2px 10px 0 1px;
    display: inline-block;
    width: 17px;
    height: 17px;
    line-height: 17px;
    text-align: center;
    content: "";
    color: #4d4d4d;
    border: 3px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #4d4d4d;
    transition: box-shadow .3s;
    background: #fff
}

.idn-form-radio input[type="radio"]+label:after {
    position: absolute;
    top: 1px;
    left: 3px;
    width: 13px;
    height: 13px;
    content: '';
    transition: background .3s;
    border-radius: 50%
}

.idn-form-radio input[type="radio"]+label:hover:before {
    box-shadow: 0 0 0 2px var(--color-1)
}

.idn-form-radio input[type="radio"]:focus+label:before {
    box-shadow: 0 0 0 2px var(--color-1)
}

.idn-form-radio input[type="radio"]:checked+label:before {
    box-shadow: 0 0 0 2px var(--color-1)
}

.idn-form-radio input[type="radio"]:checked+label:after {
    background-image: var(--gradient-1)
}

.idn-form-radio input[type="radio"]:disabled+label {
    color: #c4c4c4
}

.idn-form-radio input[type="radio"]:disabled+label:before {
    box-shadow: 0 0 0 2px #c4c4c4 !important
}

.idn-form-radio input[type="radio"]:disabled:checked+label {
    color: #c4c4c4
}

.idn-form-radio input[type="radio"]:disabled:checked+label:before {
    box-shadow: 0 0 0 2px #c4c4c4 !important
}

.idn-form-radio input[type="radio"]:disabled:checked+label:after {
    background: #c4c4c4
}

.idn-form-radios {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.idn-form-radios .idn-form-radio {
    margin-right: 30px
}

.idn-form-select {
    width: 100%;
    padding: 14px 15px;
    color: #333;
    border: 2px solid #949494;
    outline: none;
    box-sizing: border-box;
    border-radius: 5px;
    transition: border-color .3s cubic-bezier(.3, .21, .21, 1.25)
}

.idn-form-select:hover {
    border-color: var(--color-1)
}

.idn-form-select:focus {
    border-color: var(--color-1)
}



.idn-form-select[readonly],
.idn-form-select:disabled {
    color: #c4c4c4
}

.idn-form-select[readonly]+label,
.idn-form-select:disabled+label {
    color: #c4c4c4
}

.idn-form-code {
    margin-bottom: 60px
}

.idn-form-code .idn-form-label {
    margin-bottom: 20px;
    text-align: center
}

.idn-form-code .idn-form-field {
    -ms-flex-pack: center;
    justify-content: center;
    border: 0;
    background: none
}

.idn-form-code input {
    width: 25px;
    -ms-flex: 0 0 25px;
    flex: 0 0 25px;
    padding: 0 0 5px;
    margin-right: 10px;
    text-align: center;
    font-size: 1.625em;
    font-weight: bold;
    border-bottom: 3px solid #333;
    transition: border-color .3s
}

.idn-form-code input:last-child {
    margin-right: 0
}

.idn-form-code input.idn-hasvalue,
.idn-form-code input:focus {
    border-bottom-color: var(--color-1)
}

.idn-form-suffix {
    margin-right: 10px
}

.idn-form-suffix button {
    padding: 0;
    margin: 0;
    color: var(--color-1);
    font-size: 16px;
    cursor: pointer;
    border: 0;
    background: none
}

.idn-form-hint {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 5px;
    font-size: .75em;
    font-weight: bold;

    &--justify-center {
        justify-content: center;
    }
}


.idn-form-hint.idn-error {
    margin-top: 0;
    padding: 5px;
    color: #fff;
    background-color: $color-error;
    border-radius: 5px
}

.idn-form-hint.idn-warning {
    color: #ff9e1e
}

.idn-form-hint.idn-success {
    color: $color-success
}

.idn-form-hint.idn-info {
    color: var(--color-1)
}

.idn-form-hint.idn-info-gray {
    color: #666666;
}

.idn-form-hint [class*="idn-icon"] {
    margin-right: 5px;
    font-size: 14px
}

.idn-form-actions {
    margin-top: 30px;
    text-align: right
}

.idn-form-actions input,
.idn-form-actions button,
.idn-form-actions .idn-button {
    margin-left: 20px
}

.idn-form-actions input:first-child,
.idn-form-actions button:first-child,
.idn-form-actions .idn-button:first-child {
    margin-left: 0
}

.idn-form-disabled .idn-form-label {
    color: #c4c4c4
}

.idn-form-disabled .idn-form-field {
    border: 1px solid #c4c4c4 !important
}

.idn-form-disabled .idn-form-field input,
.idn-form-disabled .idn-form-field textarea {
    color: #c4c4c4
}

.idn-form-error .idn-form-label {
    color: $color-error
}

.idn-form-error .idn-form-field {
    border-color: $color-error
}

.idn-form-error .idn-form-field input,
.idn-form-error .idn-form-field textarea {
    color: $color-error
}

.idn-step {
    margin-bottom: 35px;
    font-weight: bold;
    font-size: 1.8em
}

.idn-step span {
    color: var(--color-1)
}

.idn-message {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 5px;
    background-color: #333
}

.idn-message [class*="idn-icon"] {
    width: 24px;
    -ms-flex: 0 0 24px;
    flex: 0 0 24px;
    margin-right: 10px;
    color: #fff;
    font-size: 24px
}

.idn-message-content {
    color: #fff
}

.idn-message-content a {
    color: #fff;
    text-decoration: underline
}

.idn-message.idn-info {
    background-color: #0085ca
}

.idn-message.idn-warning {
    background-color: #ff9e1e
}

.idn-message.idn-error {
    background-color: $color-error
}

.idn-align-left {
    text-align: left !important
}

.idn-align-right {
    text-align: right !important
}

.idn-align-center {
    text-align: center
}

.idn-fontweight-bold {
    font-weight: bold
}

.idn-fontweight-bold-center {
    width: 100%;
    text-align: center;
}

.idn-fontweight-bold-display-block {
    display: block;
}

.idn-font--margin-bottom {
    margin-bottom: 20px;
}

.idn-fontsize-12 {
    font-size: 0.9em !important
}

.idn-fontsize-18 {
    font-size: 1.125em !important
}

.idn-fontsize-22 {
    font-size: 1.375em !important
}

.idn-fontsize-24 {
    font-size: 1.5em !important
}

.idn-fontsize-40 {
    font-size: 2.5em !important
}

.idn-fontsize-200 {
    font-size: 12.5em !important
}

.idn-color-1 {
    color: var(--color-1) !important
}

.idn-bg-color {
    background-color: var(--color-1);
}

.idn-color-success {
    color: $color-success !important
}

.idn-hidden-scroll-bar {
    overflow: hidden;
}

.idn-color-error {
    color: $color-error !important
}

.idn-color-error-bg {
    background-color: #dcdddd !important;
}

.idn-selection {
    cursor: pointer;
    border-bottom: 1px dotted black;
    width: 100%;
    height: 100%;
}

.idn-margin-b10 {
    margin-bottom: 10px
}

.idn-margin-b20 {
    margin-bottom: 20px !important
}

.idn-margin-b30 {
    margin-bottom: 30px !important
}

.idn-margin-b50 {
    margin-bottom: 50px !important
}

.idn-hidden {
    display: none
}

.idn-section {
    width: $generel-width;
    margin: 0 auto;
    text-align: left;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--color-1);
}

.IdnPageContent {
    &--overflow {
        // height: calc(100vh - 220px);
        height: auto;
        overflow: auto;
    }

    &--ScrollPanel {
        height: calc(100vh - 350px);
        width: '100%';
    }

    &--not-overflow {
        height: calc(100vh - 220px);
    }

    &--overflow-2 {
        height: calc(100vh - 360px);
        overflow: auto;
    }

    &--minheight {
        min-height: calc(100vh - 260px);
        padding-bottom: 40px;
    }

    &--minheight2 {
        min-height: calc(100vh - 150px);
        padding-bottom: 40px;
    }
}

.PageContent {
    &--flex {
        display: flex;
        justify-content: space-between;
    }
}

.Page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

#idn-download-text {
    img {
        object-fit: scale-down;
        width: 5%;
        height: 5%;
    }
}

.idn-section.idn-centered {
    width: 550px;
    max-width: 100%;
    box-sizing: border-box;

    &--margin-bottom {
        margin-bottom: 50px;
    }
}



.idn-section.idn-centered-md {
    width: 700px;
    max-width: 100%;
    box-sizing: border-box;
}

.idn-section.idn-centered-md p:not(:first-child),
.idn-section.idn-centered-md ul {
    margin-top: 40px;
}

.idn-section.idn-centered-md ul li:not(:first-child) {
    margin-top: 20px;
}

.idn-section.idn-centered.idn-width-600 {
    width: 600px
}

#idn-download {
    padding: 5px 0;
    border-bottom: 5px solid var(--color-1);
    background: #fff
}

#idn-download .idn-section {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

#idn-download-icon {
    border: 3px solid var(--color-1)
}

#idn-download-icon img {
    display: block;
    max-height: 50px
}

#idn-download-content {
    margin: 0 15px;
    color: #333;
    text-decoration: none
}

#idn-download-title {
    font-weight: bold
}

#idn-download .idn-icon-delete {
    margin: 0;
    padding: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color: #333
}


#idn-header-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}



@counter-style quickLink {
    system: cyclic;
    symbols: ">";
    suffix: " ";
}

.list-group-item {

    margin-bottom: 24px;
    padding: 0;
    border: none;

    ul li.quickLinkHeader {
        padding: 0;
        margin-bottom: 5px;
        text-transform: capitalize;
        font-size: 18px;
        color: #000000;
    }

    ul.quickLinkList {
        padding-left: 30px;
        font-size: 16px;
    }


    .quickLinkList-li-nolink {
        color: #000000;
    }

    ul.quickLinkList li {
        .li__flex {
            display: flex;
            justify-content: space-between;
        }

    }

    ul.quickLinkList li {
        list-style-type: disc;
        margin-top: 10px;
        color: var(--color-1);
    }



    ul.quickLinkBody {
        padding: 0;
        font-family: 'Elior';
        font-style: normal;
        font-weight: 700;
    }

    ul.quickLinkBody li {
        padding: 0;
    }
}

body {
    background: var(--page-bkg) no-repeat right -70px;
}


.idn-popover {
    &__wrapper {
        position: relative;
        display: inline-block;
        width: 300%;
    }

    &__content {
        margin-top: 2rem;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transform: translate(0, 10px);
        background-color: #bfbfbf;
        padding: 1.5rem;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        width: 100%;
        z-index: 99;
    }

    &__content:before {
        position: absolute;
        z-index: -1;
        content: "";
        left: calc(10% - 10px);
        top: -8px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #bfbfbf transparent;
        transition-duration: 0.3s;
        transition-property: transform;
    }

    &__content {
        z-index: 10;
        opacity: 1;
        visibility: visible;
        transform: translate(0, -20px);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    }

    &__message {
        text-align: center;
    }
}

.showdiv {
    animation: test 1s;
}

@keyframes test {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.idn-mainarea {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    // min-height: calc(100vh - 150px);
    // height: calc(100vh - 150px)
}

.idn-page-title {
    //margin-bottom: 50px;
    margin-bottom: auto;
    font-size: 33px;
    color: #333333;
    font-family: 'Elior';
    font-style: normal;

    &--marginBottom {
        margin-bottom: 25px;
    }

    &--marginBottom-zero {
        margin-bottom: 0;
    }

    &__text {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &:after {
        display: block;
        width: 100%;
        height: 5px;
        margin-top: 5px;
        content: "";
        background: var(--gradient-1);
        border-radius: 3px;
    }
}

.idn-page-content {
    // padding-bottom: 50px
    padding-bottom: 30px;
}

.idn-centered #idn-page-header,
.idn-section--center #idn-page-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center
}

.idn-centered .idn-page-title,
.idn-section--center .idn-page-title {
    text-align: center
}


#idn-page-overlay {
    display: none;
    position: fixed;
    z-index: 100;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #313131;
    opacity: .8
}

#idn-page-overlay.idn-opened {
    display: block
}

#idn-breadcrumbs {
    margin-bottom: 10px;
    color: var(--color-1)
}

#idn-breadcrumbs a {
    color: var(--color-1)
}

#idn-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 36px;
    padding: 8px 0;
    background: var(--color-1);
    box-sizing: border-box;
    z-index: 9;

    * {
        color: #fff
    }

    .idn-section {
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    &-left,
    &-center,
    &-right {
        width: 33.33%;
    }


    &-left {
        text-align: left
    }

    &-center {
        font-weight: bold;
        text-align: center
    }

    &-right {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: end;
        justify-content: flex-end
    }
}

.idn-overflow-elipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
}


.idn-grid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

.idn-grid [class*="idn-grid12"] {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box
}

.idn-grid.idn-same-height {
    -ms-flex-align: stretch;
    align-items: stretch
}

.idn-grid.idn-same-height [class*="idn-grid12"] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch
}

.idn-grid.idn-same-height [class*="idn-grid12"]>.idn-card {
    -ms-flex: 1;
    flex: 1
}

.idn-grid12-1 {
    width: 8.33333333%
}

.idn-grid12-2 {
    width: 16.66666667%
}

.idn-grid12-3 {
    width: 25%
}

.idn-grid12-4 {
    width: 33.33333333%
}

.idn-grid12-5 {
    width: 41.66666667%
}

.idn-grid12-6 {
    width: 50%
}

.idn-grid12-7 {
    width: 58.33333333%
}

.idn-grid12-8 {
    width: 66.66666667%
}

.idn-grid12-9 {
    width: 75%
}

.idn-grid12-10 {
    width: 83.33333333%
}

.idn-grid12-11 {
    width: 91.66666667%
}

.idn-grid12-12 {
    width: 100%
}

.idn-loader {
    z-index: 8;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center
}

.idn-loader img {
    display: block;
    margin-bottom: 20px
}

.idn-loader .idn-loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.idn-loader .idn-dots {
    position: relative;
    display: inline-block;
    margin-left: -5px
}

.idn-loader .idn-dots .idn-dot {
    display: block;
    float: left;
    width: 50px;
    position: absolute
}

.idn-loader .idn-dots .idn-dot1 {
    left: -40px;
    animation: bouncy1 1.5s infinite
}

.idn-loader .idn-dots .idn-dot2 {
    left: 0;
    animation: bouncy2 1.5s infinite
}

.idn-loader .idn-dots .idn-dot3 {
    left: 40px;
    animation: bouncy3 1.5s infinite
}

.idn-loader .idn-dots .idn-dot:after {
    display: block;
    height: 10px;
    width: 10px;
    content: "";
    border-radius: 50%;
    background: var(--color-1)
}

#idn-app-loader {
    position: fixed;
    background: #FFF;
    z-index: 99;
}

#idn-app-loader-page {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
}

#idn-app-loader-session {
    position: relative;
    background: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;

    img {
        width: 25px;
        margin: 0px;
    }

    #idn-app-version {
        margin: 0px;
    }
}

.idn-loader-session {
    z-index: 8;
    top: -25px;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    .idn-dots {
        position: relative;
        display: inline-block;
        margin-left: -5px
    }

    .idn-dots .idn-dot {
        display: block;
        float: left;
        width: 50px;
        position: absolute
    }

    .idn-dots .idn-dot1 {
        left: -40px;
        animation: bouncy1 1.5s infinite
    }

    .idn-dots .idn-dot2 {
        left: 0;
        animation: bouncy2 1.5s infinite
    }

    .idn-dots .idn-dot3 {
        left: 40px;
        animation: bouncy3 1.5s infinite
    }

    .idn-dots .idn-dot:after {
        display: block;
        height: 5px;
        width: 5px;
        content: "";
        border-radius: 50%;
        background: var(--color-1)
    }
}



.idn-section--position-relative {
    position: relative;
}

#idn-content-loader {
    position: absolute;
    height: calc(100vh - 90px);
    background: rgba(255, 255, 255, 0.9)
}

#idn-content-loader img {
    margin-bottom: 10px
}

#idn-app-version {
    margin-bottom: 20px;
    font-weight: bold
}

.idn-card-list {
    width: 100%;
    margin: 0;
}





.idn-pagination {
    list-style: none;
    text-align: center
}

.idn-pagination li {
    display: inline-block
}

.idn-pagination li a {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 15px;
    color: var(--color-1);
    text-align: center;
    border: 2px solid transparent;
    border-radius: 50%;
    transition: background .3s
}

.idn-pagination li a.idn-prev {
    font-size: .6875em;
    line-height: 33px
}

.idn-pagination li a.idn-prev:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e914"
}

.idn-pagination li a:hover {
    color: #fff;
    text-decoration: none;
    border-color: var(--color-1);
    background: var(--gradient-1)
}

.idn-pagination li a.idn-next {
    margin-right: 0;
    font-size: .6875em;
    line-height: 33px
}

.idn-pagination li a.idn-next:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e900"
}

.idn-pagination li a.idn-current {
    cursor: default;
    color: var(--color-1);
    border-color: var(--color-1);
    background: #fff
}

.idn-pagination li a.idn-disabled {
    color: #bbb !important;
    cursor: default;
    border-color: transparent !important;
    background: none !important
}

.idn-404 {
    background: var(--page-bkg-2) no-repeat 1000px 150px, var(--page-bkg-3) no-repeat left 50%, var(--page-bkg-4) no-repeat right bottom
}

.idn-ddown {
    position: relative;
    z-index: 2;
    display: inline-block
}

.idn-ddown-trigger {
    display: inline-block;
    font-size: 1em
}

.idn-ddown-trigger:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e913"
}

.idn-ddown-trigger:before {
    display: block;
    float: right;
    margin: 1px 0 0 15px;
    transition: all .3s;
    font-size: .75em
}

.idn-ddown-target {
    position: absolute;
    top: 30px;
    left: -20px;
    margin: 0;
    padding: 0 20px;
    width: 100%;
    list-style: none;
    background: #FFF;
    box-shadow: 0 0 30px 0 rgba(50, 50, 50, 0.4);
    transition: all .3s ease-in;
    transform-origin: 50% 0;
    transform: scaleY(0)
}

.idn-ddown-target li {
    border-top: 1px solid #d4d4d4
}

.idn-ddown-target li:first-child {
    border: 0
}

.idn-ddown-target li a {
    display: block;
    padding: 15px 0;
    color: #666666
}

.idn-ddown-target li a:hover {
    text-decoration: none;
    color: var(--color-1)
}

.idn-ddown.idn-opened .idn-ddown-trigger:before {
    rotate: 180deg
}

.idn-ddown.idn-opened .idn-ddown-target {
    transform: scaleY(1)
}

.idn-toggle {
    position: relative;
    z-index: 2;
    display: inline-block
}

.idn-toggle-trigger {
    display: inline-block
}

.idn-toggle-trigger:after {
    display: block;
    float: right;
    width: 0;
    height: 0;
    margin: 3px 0 0 30px;
    content: "";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 6px solid var(--color-1)
}

.idn-toggle-target {
    width: 100%;
    padding: 0 20px;
    background: #FFF;
    box-shadow: 0 0 30px 0 rgba(50, 50, 50, 0.4);
    transition: all .3s ease-in;
    transform-origin: 50% 0;
    transform: scaleY(0)
}

.idn-toggle-target li {
    border-top: 1px solid #d4d4d4
}

.idn-toggle-target li:first-child {
    border: 0
}

.idn-toggle-target li a {
    display: block;
    padding: 15px 0;
    color: #666666
}

.idn-toggle-target li a:hover {
    text-decoration: none;
    color: var(--color-1)
}

.idn-toggle.idn-opened .idn-toggle-target {
    transform: scaleY(1)
}

.idn-ismobile {
    display: none
}

#idn-nav-trigger {
    position: relative;
    float: right;
    width: 25px;
    height: 25px;
    margin-top: 20px;
    margin-bottom: 20px
}

#idn-nav-trigger span {
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    border-top: 3px solid #303133;
    transition: .55s
}

#idn-nav-trigger span:first-child {
    top: 0
}

#idn-nav-trigger span:first-child+span {
    top: 10px
}

#idn-nav-trigger span:first-child+span+span {
    top: 20px
}

#idn-nav-trigger.idn-opened span:first-child {
    top: 10px;
    transform: rotate(45deg);
    transition: .55s .5s;
    backface-visibility: hidden
}

#idn-nav-trigger.idn-opened span:first-child+span {
    opacity: 0
}

#idn-nav-trigger.idn-opened span:first-child+span+span {
    top: 10px;
    transform: rotate(-405deg);
    transition: .55s .5s;
    backface-visibility: hidden
}

#idn-leftnav-trigger {
    position: relative;
    color: #303133;
    float: right;
    margin-top: 19px;
    margin-bottom: 18px;
    margin-right: 20px;
    transition: all .3s
}

#idn-leftnav-trigger:hover {
    text-decoration: none
}

#idn-leftnav-trigger:before {
    display: inline-block;
    font-size: 1.5625em
}

#idn-leftnav-trigger.idn-opened {
    rotate: 180deg
}

#idn-nav-back {
    float: left;
    width: 25px;
    height: 25px;
    margin: 20px 0 0;
    opacity: 0;
    transition: opacity .3s
}

#idn-nav-back:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e914"
}

#idn-nav-back:before {
    display: inline-block;
    font-size: 1.5625em
}

#idn-nav-back.idn-active {
    opacity: 1
}

#idn-nav-context {
    position: absolute;
    top: 25px;
    left: 50px;
    right: 50px;
    text-transform: uppercase;
    font: 'Elior', Arial, Helvetica, sans-serif;
    text-align: center;
    opacity: 0;
    transition: opacity .3s
}


#idn-nav-context.idn-active {
    opacity: 1
}

.popkiesReset {
    text-decoration: underline;
    margin-bottom: 15px;
    cursor: pointer;
}

.CookieConsent__Style {
    width: 800px;
    background: rgb(255, 255, 255);
    color: black;
    z-index: 999;
    padding: 70px;
    overflow: auto;
}

.CookieConsent__Style h1 {
    margin-bottom: 30px;
}

.CookieConsent__Style dt {
    font-weight: bold;
    margin-bottom: 5px;
}

.CookieConsent__Style dd {
    margin-left: 15px;
    margin-bottom: 15px;
}

.CookieConsent__Style #manage {
    text-align: right;
    text-decoration: underline;
    margin-bottom: 15px;
    cursor: pointer;
}

.CookieConsent__OverlayStyle {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.CookieConsent__buttonWrapperClasses {
    width: 100%;
    display: flex;
    justify-content: center;
}


.CookieConsent__buttonClasses,
.CookieConsent__declineButtonClasses {
    background: rgb(0, 0, 0);
    border: 0px;
    border-radius: 20px;
    box-shadow: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 15px;
    min-width: 250px;
    margin-top: 10px;
}

.CookieConsent__buttonClasses {
    margin-right: 20px;
}


@media all and (min-width:2000px) {
    .idn-section {
        width: $generel-width-sup-2000;
    }
}

@media all and (max-width: 2000px) and (min-width: 1500px) {
    .idn-section {
        width: $generel-width-sup-1500;
        padding: 0 30px
    }
}

@media all and (max-width:1500px) and (max-height:760px) {
    .IdnPageContent {
        &--overflow {
            height: calc(100vh - 250px);
            overflow: auto;
        }

        &--not-overflow {
            height: calc(100vh - 220px);
        }

    }
}

@media all and (max-width:1500px) {
    .idn-page-title {
        font-size: 26px
    }

    .IdnPageContent {

        &--ScrollPanel {
            height: calc(100vh - 340px);
            width: '100%';
        }
    }
}

@media all and (max-width:1361px) {
    .idn-section {
        width: $generel-width-sup-1361;
    }
}

@media all and (max-width:1255px) {
    .idn-section {
        width: $generel-width-sup-1255;
    }

    .idn-page-title {
        font-size: 24px
    }
}

@media all and (max-width:1200px) {
    .IdnPageContent {
        &--ScrollPanel {
            height: calc(100vh - 380px);
            width: '100%';
        }
    }
}

@media all and (max-width:1175px) {

    .idn-page-title {
        font-size: 22px
    }
}

@media all and (max-width:1140px) {
    .idn-section {
        width: $generel-width-sup-1140;
        padding: 0 30px
    }

    .idn-section.idn-centered {
        width: 560px
    }

    .idn-page-title {
        font-size: 20px
    }
}

@media all and (max-width:1085px) {
    .idn-section {
        width: $generel-width-sup-1085;
    }

    .idn-page-title {
        font-size: 20px
    }
}

@media all and (max-width:1024px) {
    .idn-section {
        width: $generel-width-sup-1024;
    }

    body {
        background: none
    }


    .idn-404 {
        background: none
    }

    .IdnPageContent {
        &--ScrollPanel {
            height: calc(100vh - 340px);
        }
    }
}


@media all and (max-width:900px) {
    .IdnPageContent {
        &--ScrollPanel {
            height: calc(100vh - 380px);
        }
    }

}

@media all and (max-width:800px) {

    .IdnPageContent {
        &--overflow {
            height: auto;
            overflow: none;
        }

        &--ScrollPanel {
            height: auto;
            width: '100%';
        }

        &--not-overflow {
            height: auto;
        }

        &--minheight {
            min-height: 0;
        }

        &--overflow-2 {
            height: auto;
            overflow: auto;
        }
    }

    .idn-app-content {
        &--mobile-minheight {
            min-height: calc(100vh - 320px);
        }

        &--mobile-minheight2 {
            min-height: calc(100vh - 200px);
            margin-bottom: 60px;
        }
    }


    .message-box,
    .idn-app-content,
    .homeContent {
        width: auto;
    }

    .idn-user-group {
        padding: 0 10px;
    }

    .homeContent {
        flex-direction: column-reverse;

        &--flex-direction-column {
            flex-direction: column;
        }
    }

    .idn-grid {
        display: block;
        margin-left: 0;
        margin-right: 0
    }

    .idn-grid [class*="idn-grid12"] {
        width: 100%;
        padding-left: 0;
        padding-right: 0
    }

    #idn-footer {

        .idn-section {
            justify-content: center;
            align-items: center;
        }

        &-left {
            display: none;
        }

        &-center,
        &-right {
            width: 50%;
            text-align: center;
        }


        &-right {
            justify-content: center
        }
    }

    .CookieConsent__Style {
        padding: 30px;
    }

    .CookieConsent__buttonClasses,
    .CookieConsent__declineButtonClasses {
        width: 100%;
        margin: 5px 0 5px 0;
    }

    .CookieConsent__buttonWrapperClasses {
        flex-wrap: wrap;
    }

    .show-on-mobile {
        display: block;
        width: 100%;
    }
}



@media all and (max-width:650px) {

    .idn-card {
        width: 100%;

        &__header {
            width: 100%;
        }

        &__content {
            width: 100%;
        }
    }

    .idn-section {
        padding-left: 20px;
        padding-right: 20px
    }

}

@media all and (max-width:390px) {

    #cookiesAccept h1 {
        font-size: 22px;
    }

    #cookiesAccept p {
        font-size: 14px;
    }

    .CookieConsent {

        &__buttonClasses,
        &__declineButtonClasses {
            font-size: 14px;
        }
    }

    .idn-fontsize-200 {
        font-size: 9.375em !important
    }

    #idn-footer {
        .idn-section {
            padding: 0;
            justify-content: space-around;
        }

        &-center,
        &-right {
            width: auto;
        }
    }

    .idn-button.idn-size-l {
        font-size: 18px;
    }

}


@media all and (max-width:1024px) and (max-height:600px) {
    .idn-page-title {
        margin-bottom: 40px;
    }

    hr {
        &.idn-ruller {
            margin: 10px auto;
        }
    }
}

@media all and (max-width:640px) and (max-height:360px) {
    .idn-mainarea {
        height: auto;
    }

    #cookiesAccept h1 {
        font-size: 22px;
    }

    #cookiesAccept p {
        font-size: 14px;
    }

    .CookieConsent {

        &__buttonClasses,
        &__declineButtonClasses {
            font-size: 14px;
        }
    }

    .idn-page-title {
        margin-bottom: 5px;
        font-size: 24px;
    }

    .idn-form-label {
        margin-bottom: 2px;
        font-size: 0.9em;
    }

    .idn-form-code .idn-form-label {
        margin-bottom: 5px;
    }

    .idn-form-item {
        margin-bottom: 5px
    }

    hr {
        &.idn-ruller {
            margin: 10px auto;
        }
    }

    .idn-button {
        line-height: 35px;
        height: 35px;

        &.idn-size-l {
            line-height: 35px;
            height: 35px;
        }

        &--square {
            height: 152px;
            line-height: 15px;
        }

    }

    .idn-form-field input,
    .idn-form-field textarea {
        padding: 8px 5px;
    }

    .idn-margin-b30 {
        margin-bottom: 5px !important
    }

    .idn-form-actions {
        margin-top: 5px;
    }

    .idn-step {
        margin-bottom: 10px;
        font-size: 1.5em;
    }

    .idn-margin-b50 {
        margin-bottom: 20px !important
    }
}

@media all and (max-width:412px) {
    .idn-button {

        &--square {
            width: 100%;
        }

    }
}

@media all and (max-width:360px) and (max-height:640px) {
    .idn-page-title {
        margin-bottom: 20px;
    }
}


@media all and (max-width:320px) {

    hr {
        &.idn-ruller {
            margin: 15px auto;
        }
    }

    .idn-button {

        &.idn-size-l {
            line-height: 45px;
            height: 45px;
        }

        &--square {
            width: 100%;
        }
    }

    .idn-form-actions {
        margin-top: 5px;
    }


    .idn-form-radio input[type="radio"]+label:after {
        top: 8.5px;
        left: 2px;
    }

    .idn-page-title {
        font-size: 23.5px
    }

    .idn-step {
        font-size: 1.4em;
    }

}

@media all and (max-width:320px) and (max-height:480px) {

    .idn-form-radio input[type="radio"]+label:after {
        top: 7px;
        left: 3px;
    }

    .idn-form-label {
        margin-bottom: 2px;
        font-size: 0.9em;
    }

    .idn-form-code .idn-form-label {
        margin-bottom: 5px;
    }

    hr {
        &.idn-ruller {
            margin: 10px auto;
        }
    }

    .idn-button {
        &.idn-size-l {
            line-height: 45px;
            height: 45px;
        }
    }


    .idn-margin-b30 {
        margin-bottom: 5px !important
    }

    .idn-form-actions {
        margin-top: 5px;
    }

    .idn-step {
        margin-bottom: 10px;
        font-size: 1.5em;
    }

    .idn-margin-b50 {
        margin-bottom: 30px !important
    }
}

@media all and (max-width:240px) and (max-height:320px) {



    #cookiesAccept h1 {
        font-size: 15px;
        margin-bottom: 10px;
    }

    #cookiesAccept p {
        font-size: 10px;
        margin-bottom: 5px;
    }

    .CookieConsent {
        &__Style #manage {
            font-size: 10px;
            margin-bottom: 5px;
        }

        &__buttonClasses,
        &__declineButtonClasses {
            font-size: 8px;
            min-width: 180px;
            padding: 6px 15px;
            margin-top: 5px;
        }
    }

    .idn-page-title {
        margin-bottom: 5px;
        font-size: 18px;
    }

    .idn-form-hint {
        margin-top: 5px;
        font-size: .4em;
    }

    .idn-form-label {
        margin-bottom: 2px;
        font-size: 0.8em;
    }

    .idn-form-code .idn-form-label {
        margin-bottom: 5px;
    }

    .idn-form-item {
        margin-bottom: 5px
    }

    hr {
        &.idn-ruller {
            margin: 5px auto;
        }
    }

    .idn-button {
        line-height: 30px;
        height: 30px;

        &.idn-size-l {
            line-height: 30px;
            height: 30px;
            font-size: 0.8em
        }

        &--square {
            width: 100%;
            height: 80px;
        }
    }

    .idn-form-field input,
    .idn-form-field textarea {
        padding: 5px 3px;
    }

    .idn-margin-b30 {
        margin-bottom: 5px !important
    }

    .idn-form-actions {
        margin-top: 5px;
    }

    .idn-step {
        margin-bottom: 10px;
        font-size: 1em;
    }

    .idn-margin-b50 {
        margin-bottom: 20px !important
    }

    .idn-fontsize-24 {
        font-size: 1em !important
    }

    .idn-fontsize-18 {
        font-size: .8em !important
    }

    .idn-fontsize-16 {
        font-size: .6em !important
    }

    .idn-fontsize-12 {
        font-size: 0.5em !important
    }

}

@media print {

    .s4-notdlg,
    #s4-statusbarcontainer {
        display: none
    }

    #idn-header,
    #idn-topnav,
    #idn-footer {
        display: none
    }

    body {
        background: none !important
    }
}