.icon-size {
    width: 24px;
    height: 24px;
}

#idn-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: center;
    margin-bottom: 55px;
    margin-top: 15px;
    padding: 0;
    font-family: 'Elior';
    font-style: normal;
    font-weight: bold;
    font-size: 1.1em;
    color: #FFFFFF;


    #idn-logo img {
        object-fit: contain;
        display: block;
        height: auto;
        width: auto
    }

    #idn-logo-cmp {
        height: 34px;
        width: 200px;
        background-image: var(--logo);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    .headerContent {
        width: $generel-width;
        display: flex;
        justify-content: space-between;
    }
}

#idn-header-right {
    .idn-user-menu:not(:first-child) {
        padding-left: 20px;
    }
}


.idn-user-menu {
    position: relative;

    &--padding {
        padding: 16px 0;
    }

    &--center {
        text-align: center;
    }

    &:hover &__nav {
        display: block;
        animation: displayIdnUserNav 350ms both;
    }

    &__nav ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__nav {

        right: 0;
        text-align: left;
        position: absolute;
        display: none;
        background-color: white;
        opacity: 0;
        transition: display 3500ms;
        color: black;
        border-radius: 5px;
        padding: 0;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
        z-index: 1;
    }

    &__nav-title {
        padding: 16px 16px 5px 16px;
        font-size: 15px;
        display: flex;
        align-items: center;
        text-align: left;
        color: var(--color-1);
    }

    &__nav-footer {
        font-size: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-1);
        padding: 10px;

        &--color {
            color: rgb(128, 128, 128);
        }
    }

    &__nav-title::after {
        margin-left: 5px;
        content: "";
        border: 1px solid var(--color-1);
        flex: 1;
    }

    .idn-user {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0;
        color: #fff;
        cursor: pointer;
        border: none;
        background: none;

        .idn-icon-profile,
        .idn-icon-notif {
            font-size: 24px;
            position: relative;
        }

        .idn-icon-logout {
            font-size: 28px
        }
    }

    .idn-user-name {
        font-family: 'Elior';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: #FFFFFF;
        margin-right: 10px;
    }


}


@media all and (min-width:2000px) {
    #idn-header {
        padding: 0;

        .headerContent {
            width: $generel-width-sup-2000;
        }
    }

}

@media all and (max-width: 2000px) and (min-width: 1500px) {
    #idn-header {
        padding: 0;

        .headerContent {
            width: $generel-width-sup-1500;
            padding: 0 30px
        }
    }

}

@media all and (max-width:1361px) {
    #idn-header {
        .headerContent {
            width: $generel-width-sup-1361;
        }
    }
}

@media all and (max-width:1255px) {
    #idn-header {
        .headerContent {
            width: $generel-width-sup-1255;
        }
    }
}


@media all and (max-width:1140px) {
    #idn-header {
        .headerContent {
            width: $generel-width-sup-1140;
            padding: 0 30px;
        }
    }
}

@media all and (max-width:1085px) {
    #idn-header {
        .headerContent {
            width: $generel-width-sup-1085;
        }
    }
}

@media all and (max-width:1024px) {

    #idn-header {
        .headerContent {
            width: $generel-width-sup-1024;
        }
    }

    .idn-user-menu {
        .idn-user-name {
            text-decoration-line: none;
            font-size: 14px;
            color: #333333;
        }

        .idn-user {
            color: #333333;
        }
    }
}

@media all and (max-width:800px) {
    .idn-user-menu {

        &__nav {
            width: auto;
        }

        &--size {
            width: 100%;
        }

        &--position {
            position: initial;
        }
    }

    #idn-header {
        #idn-logo-cmp {
            width: 110px;
        }
    }
}

@media all and (max-width:650px) {

    #idn-header {
        #idn-logo {
            margin: 0
        }
    }
}

@media all and (max-width:390px) {

    #idn-header {
        .headerContent {
            padding: 0 15px;
        }
    }

    #idn-header-right {
        .idn-user-menu:not(:first-child) {
            padding-left: 5px;
        }
    }

    #idn-logo-cmp {
        width: 100px;
        height: 54px;
    }
}


@media all and (max-width:1024px) and (max-height:600px) {
    #idn-header {
        margin-bottom: 45px;
    }
}

@media all and (max-width:640px) and (max-height:360px) {
    #idn-header {
        margin-bottom: 5px;
        margin-top: 5px;
    }
}

@media all and (max-width:360px) and (max-height:640px) {
    #idn-header {
        margin-bottom: 20px;
        margin-top: 5px;
    }
}

@media all and (max-width:240px) and (max-height:320px) {
    #idn-header {
        margin-bottom: 5px;
        margin-top: 5px;
    }
}

@media all and (max-width:240px) {

    .idn-user-menu {
        .idn-user-name {
            display: none;
        }
    }
}