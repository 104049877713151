.home {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &__section {
        display: flex;
        justify-content: space-between;
    }
}